.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child){
  border-radius: 50px 0px 0px 50px !important;
  font-size: 1rem;
  padding: 10px 15px;
  width: 80px;
  background-color: white;
  color: #000;
  border: 1px solid #e0e0e0;
}

.fc-daygrid-day:hover {
  background-color: #72e6fa41;
  transition: background-color 0.3s ease;
  cursor: pointer;
  transform: scale(1.05);
}

.fc-event-content {
  border-radius: 1rem !important;
  font-size: inherit !important;
    font-weight: bold !important;
    padding: 0.5rem;
}

.fc-daygrid-day.fc-day-today:hover {
  background-color: #d1e7dd;
  /* Different color for today's date */
}

.fc-daygrid-day.fc-day-past:hover {
  background-color: #f8d7da;
  /* Subtle red tint for past dates */
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child):not(:first-child) {
  border-radius: 00px 0px 0px 00px !important;
  font-size: 1rem;
  padding: 10px 15px;
  width: 80px;
  background-color: white;
  color: #000;
  border: 1px solid #e0e0e0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-radius: 0px 50px 50px 0px !important;
  font-size: 1rem;
  padding: 10px 15px;
  width: 80px;
  background-color: white;
  color: #000;
  border: 1px solid #e0e0e0;
}
.fc .fc-button-group > .fc-button.fc-button-active, .fc .fc-button-group > .fc-button:active, .fc .fc-button-group > .fc-button:focus, .fc .fc-button-group > .fc-button:hover {
  background-color: #5A8DFF;
  color: white;
  border: none !important;
  outline: none !important;
}
.fc-direction-ltr .fc-button-group > .fc-button.fc-button-active:not(:last-child):not(:first-child) {
  border-radius: 00px 0px 0px 00px !important;
  font-size: 1rem;
  padding: 10px 15px;
  width: 80px;
  background-color: white;
  color: #000;
  border: 1px solid #e0e0e0;
}
.fc .fc-toolbar-title {
  font-size: 1.3rem;
}
/* .fc-today-button{
  display: none !important;
} */
.fc-custom1-button{
  display: none !important;
}
.fc .fc-toolbar.fc-header-toolbar {}
.fc-toolbar-chunk{}


.button-default {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  color: #000;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.button-default:hover {
  background-color: #e0e0e0;
}

.button-danger {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.button-danger:hover {
  background-color: #d32f2f;
}

.button-info {
  background-color: #2196F3;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.button-info:hover {
  background-color: #1976D2;
}

.calWrapper .fc-day-other {
  background-image: url('../../assets/diagonal-lines.svg');
  background-size: cover;
  background-position: center;
}
.calWrapper .fc-col-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #F1F4F9;
  padding: 5px 0;
}

.calWrapper .fc-col-header  .fc-col-header-cell {
  border: 0;
  
}
.calWrapper .fc-col-header .fc-col-header-cell .fc-scrollgrid-sync-inner {
  padding:  5px 0;
}
.calWrapper  .fc-scrollgrid {
  border: none;
}

.calWrapper .fc .fc-daygrid-body {
  border-left: 1px solid #e0e0e0;
}

.calWrapper .fc-scrollgrid-section-header > th {
 border: 0;
}