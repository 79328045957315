@tailwind base;
@tailwind components;
@tailwind utilities;
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
    /* width: .4em; */
    display: none;
  }
  
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgb(201, 201, 201);
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    outline: 1px solid #e5e5e5;
    border-radius: 0px;
  
  }
  div::-webkit-scrollbar {
    width: .4em;
    padding: 5px 5px;
  }
  
  div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgb(255, 255, 255);
    padding: 5px 5px;
  }
  
  div::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    outline: 1px solid #e5e5e5;
    border-radius: 0px;
    
  }
  .dialogBox::-webkit-scrollbar{
    width: .4em;
      padding: 5px 5px;
  }
  .dialogBox::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgb(201, 201, 201);
    padding: 5px 5px;
  }
  
  .dialogBox::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    outline: 1px solid #e5e5e5;
    border-radius: 0px;
  
  }

  blockquote {
    font-style: italic;
  }
  blockquote > p {
    display:inline;
  }
  blockquote:before {
    content: '“';
  }
  blockquote:after {
    content: '”';
  }



  .rte ol, .rte ul {
    list-style: auto;
    margin-left: 20px;
  }


  .progamActionMenu .dropdown-toggle::after {
    display: none;
  }
  .progamActionMenu .dropdown-item{
    padding: 0;
  }

  .mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
  }
  .sidebar {
    flex-direction: row;
    width: 250px;
  }

  .rightContainer {
    width: calc(100vw - 250px);
  }


  .scal-select {
    position: relative;
  }

  .scal-select select {
    background-color: #F5F6FA;
    border: 0.6px solid #D5D5D5;
  }
  .eventModal input,.eventModal select {
    background-color: #F5F6FA;
    border: 0.6px solid #D5D5D5;
    padding: .65rem !important;
  }
  .scal-icon {
    right: 10px;
  }

  .fc-daygrid-event-harness {
    display: inline-block !important;
  }
  .fc-daygrid-event-harness .fc-event-title {
    display: none !important;
  }

  .date-time-area .fc-event-content{
    padding: 0.4rem !important;
  }
  /* :root {
    --fc-border-color: black;
    --fc-daygrid-event-dot-width: 5px;
  } */