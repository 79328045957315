/* calendar */

/* .fc-daygrid-day:hover {
  background-color: #72e6fa41 !important;
  transition: background-color 0.3s ease !important;
  cursor: pointer !important;
  transform: scale(1.05) !important;
}

.fc-daygrid-day.fc-day-today:hover {
  background-color: #d1e7dd !important;
}

.fc-daygrid-day.fc-day-past:hover {
  background-color: #f8d7da !important;
} */

.date-time-area .fc-toolbar-title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.date-time-area .fc-button {
  background: #fff !important;
  color: #000 !important;
  border: none !important;
  box-shadow: none !important;
}

.date-time-area .fc-toolbar-chunk .fc-button-active {
  border-bottom: 3px solid #577cff !important;
  border-radius: 0;
  box-shadow: none !important;
}

.date-time-area .fc-event-main {
  border-radius: 1rem !important;
  padding:0.5rem;
  /* background-color: #5f33e1; */
}

.date-time-area .fc-event-content {
  border-radius: 1rem !important;
  font-size: inherit !important;
  font-weight: bold !important;
  padding: 0.5rem;
  /* background-color: #5f33e1; */
}

.date-time-area .fc-v-event {
  border-radius: 1rem !important;
}

.date-time-area .fc-ltr .fc-h-event.fc-not-end,
.date-time-area .fc-rtl .fc-h-event.fc-not-start {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

.date-time-area .fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
  opacity: 0.65 !important;
  margin-left: 12px !important;
  padding: 5px !important;
}

.date-time-area .fc-timegrid-slot-lane {
  background-color: #fff !important;
}

.date-time-area .fc-scrollgrid-sync-inner {
  background-color: #fff !important;
}

.date-time-area .fc-event-content {
  cursor: pointer;
}

.date-time-area .fc-timeGridDay-view .fc-scrollgrid>tbody>tr:nth-child(1),
.date-time-area .fc-timeGridDay-view .fc-scrollgrid>tbody>tr:nth-child(2),
.date-time-area .fc-timeGridDay-view thead,
/* .fc-scrollgrid>tbody>tr:nth-child(3) */
.date-time-area .fc-timeGridWeek-view .fc-scrollgrid>tbody>tr:nth-child(1),
.date-time-area .fc-timeGridWeek-view .fc-scrollgrid>tbody>tr:nth-child(2),
.date-time-area .fc-timeGridWeek-view thead
/* .date-time-area .fc-timeGridWeek-view .fc-scrollgrid>tbody>tr:nth-child(3) */
{
display:none;
}

.date-time-area .fc-timeGridDay-view .fc-scrollgrid, .date-time-area .fc-timeGridWeek-view .fc-scrollgrid{
  border:none !important;
}
.date-time-area .fc-scrollgrid-shrink-cushion{
  width:1.7rem;
  white-space: wrap !important;
  word-wrap:break-word !important;
  text-transform: uppercase !important;
  text-align: center;
  color:#9F9F9F;
}

/* now indicator */
.date-time-area .fc-direction-ltr .fc-timegrid-now-indicator-arrow {
  /* left: 0; */
  border-width: 10px 0px 10px 0px;
  /* border-radius: 0px 10px 10px 0px; */
  opacity: .7;
  color: #577cff;
  border-left-color:#577cff;
  border-left-width: 1rem;
  margin-top: -10px !important;
}

/* .fc .fc-timegrid-now-indicator-arrow {
  position: absolute;
  z-index: 4;
  margin-top: -10px;
  border-style: solid;
  border-color: #577cff;
  color: #577cff;
} */

.date-time-area .fc .fc-timegrid-now-indicator-line {
  opacity: .7;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  margin-top: -2px;
  border-style:dashed;
  border-color: #577cff;
  /* border-color: var(— fc-now-indicator-color, #577cff); */
  border-width: 3px 0 0;
}

/* .fc-scrollgrid-section .fc-scrollgrid-section-header{
  display:none;
} */
/* .fc-daygrid-body .fc-scrollgrid-sync-table tr:first-of-type{
  display:none;
  border:none !important;
} */

 /* thead {
  display:none;
  border:none !important;
} */

/*tr:first-of-type {
  display: none;
} */

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.modal-content {
  padding: 15px;
  font-family: "Arial", sans-serif;
}

.modal-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.modal-icon {
  margin-right: 10px;
  color: #007bff;
  font-size: 18px;
}

.close-icon {
  cursor: pointer;
  color: #888;
  font-size: 24px;
}